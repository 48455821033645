import React from "react";
import PropTypes from "prop-types";
import Grow from "@material-ui/core/Grow";

const Shower = ({ children }) => (
  <>
    {children.map((node, index) => (
      <Grow in={true} key={index}>
        {node}
      </Grow>
    ))}
  </>
);
Shower.defaultProp = {};

Shower.propTypes = {
  children: PropTypes.node,
};

export default Shower;
