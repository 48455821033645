import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import profile from "assets/img/faces/olikirollio-avatar.jpg";

import studio3 from "assets/img/oli/oli3.jpg";
import studio1 from "assets/img/oli/oli1.jpg";
import studio2 from "assets/img/oli/oli2.jpg";
import studio4 from "assets/img/oli/oli4.jpg";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Shower from "../../components/Shower";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked((prev) => !prev);
  };

  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);

  return (
    <div style={{ background: "#00061ce8" }}>
      <Header
        color="transparent"
        brand="Olikirolli"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        small
        filter
        image={require("assets/img/profile-bg.jpg").default}
      />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Olga Petrenko</h3>
                    <h6>2D Artist</h6>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-behance"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-instagram"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}>
                      <i className={"fab fa-facebook"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Graphic Designer based in Kiev, Ukraine love all paris of the
                creative process and learning new skills.I work meticulously and
                always seek fo Improve myself. I have a good sense of team work
                and camaraderie and enjoy meeting пew people and taking on пеv
                Interests
              </p>
            </div>
          </div>
        </div>
        <GridContainer justify="center" style={{ padding: 20 }}>
          {!checked ? (
            <GridItem xs={12} sm={12} md={2} onClick={handleChange}>
              <img alt="..." src={studio3} className={navImageClasses} />
            </GridItem>
          ) : (
            <Shower checked={checked}>
              <GridItem xs={12} sm={12} md={2} onClick={handleChange}>
                <img alt="..." src={studio3} className={navImageClasses} />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <img alt="..." src={studio1} className={navImageClasses} />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <img alt="..." src={studio2} className={navImageClasses} />
              </GridItem>
              <GridItem xs={12} sm={12} md={2}>
                <img alt="..." src={studio4} className={navImageClasses} />
              </GridItem>
            </Shower>
          )}
        </GridContainer>
      </div>
      <Footer />
    </div>
  );
}
